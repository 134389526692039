<div iknLoader [isActive]="isLoading" class="loader-container"></div>
<div class="page-content">
  <!-- <app-top-header class='top-bar-container' bgStyle="solid" [screenTitle]="screenTitle | translate"></app-top-header> -->

  <app-top-header
    class="top-bar-container"
    [isServiceScreen]="true"
    [bgStyle]="'solid'"
    [screenTitle]="screenTitle | translate"
  ></app-top-header>
  <!-- <div class="cat-clearfix">&nbsp;</div> -->
  <div *ngIf="!isLoading" class="page-wrapper">
    <div class="content bg-gray">
      <section class="categories-labels-sec bg-white border-bottom-none">
        <div class="container bg-gray pb-2 pt-4 d-none">
          <div
            style="border-bottom: 2px solid #f5f5f5"
            class="row justify-content-start align-items-start pt-3"
          >
            <div class="col-auto">
              <div class="side-menu">
                <span class="font-weight-bold px-2 color-black">Method</span>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="container">
          <ul *ngIf="canSelectService" class="categories-labels">
            <ng-container *ngFor="let srv of services; let i = index">
              <li [id]="'lisrv' + srv?.id">
                <button
                  class="btn btn-outline-secondary d-block service-button text-center py-4"
                  [ngClass]="{ 'active-cat': selectedService == srv }"
                  (click)="setService(srv)"
                >
                  <div>
                    <svg
                      *ngIf="i == 0"
                      style="width: 20px !important"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 640 512"
                    >
                      <path
                        d="M112 0C85.5 0 64 21.5 64 48V96H16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 272c8.8 0 16 7.2 16 16s-7.2 16-16 16H64 48c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 240c8.8 0 16 7.2 16 16s-7.2 16-16 16H64 16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 208c8.8 0 16 7.2 16 16s-7.2 16-16 16H64V416c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H112zM544 237.3V256H416V160h50.7L544 237.3zM160 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm272 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z"
                      />
                    </svg>
                    <svg
                      *ngIf="i == 1"
                      style="width: 20px !important"
                      xmlns="http://www.w3.org/2000/svg"
                      width="24.647"
                      height="24.653"
                      viewBox="0 0 24.647 24.653"
                    >
                      <g
                        id="Icon_ionic-ios-timer"
                        data-name="Icon ionic-ios-timer"
                        transform="translate(-3.938 -3.938)"
                      >
                        <path
                          id="Path_2306"
                          data-name="Path 2306"
                          d="M16.264,28.59a12.327,12.327,0,0,1-8.555-21.2A.989.989,0,1,1,9.084,8.813,10.345,10.345,0,1,0,17.25,5.965V9.823a.992.992,0,1,1-1.985,0V4.93a.992.992,0,0,1,.992-.992,12.326,12.326,0,0,1,.006,24.653Z"
                          transform="translate(0)"
                          fill="#707070"
                        />
                        <path
                          id="Path_2307"
                          data-name="Path 2307"
                          d="M12.23,11.367l6.132,4.407a1.856,1.856,0,1,1-2.157,3.02,1.792,1.792,0,0,1-.431-.431L11.367,12.23a.618.618,0,0,1,.863-.863Z"
                          transform="translate(-0.903 -0.903)"
                          fill="#707070"
                        />
                      </g>
                    </svg>

                    <svg
                      *ngIf="i == 2"
                      style="width: 20px !important"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 448 512"
                    >
                      <path
                        d="M320 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM125.7 175.5c9.9-9.9 23.4-15.5 37.5-15.5c1.9 0 3.8 .1 5.6 .3L137.6 254c-9.3 28 1.7 58.8 26.8 74.5l86.2 53.9-25.4 88.8c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l28.7-100.4c5.9-20.6-2.6-42.6-20.7-53.9L238 299l30.9-82.4 5.1 12.3C289 264.7 323.9 288 362.7 288H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H362.7c-12.9 0-24.6-7.8-29.5-19.7l-6.3-15c-14.6-35.1-44.1-61.9-80.5-73.1l-48.7-15c-11.1-3.4-22.7-5.2-34.4-5.2c-31 0-60.8 12.3-82.7 34.3L57.4 153.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l23.1-23.1zM91.2 352H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h69.6c19 0 36.2-11.2 43.9-28.5L157 361.6l-9.5-6c-17.5-10.9-30.5-26.8-37.9-44.9L91.2 352z"
                      />
                    </svg>
                    {{ "srv_" + srv | translate }}
                  </div>
                </button>
              </li>
            </ng-container>
          </ul>
        </div> -->

        <section class="categories-labels-sec py-3" *ngIf="canSelectService">
          <div class="container container-padding-5">
            <div class="d-flex">
              <ul class="categories-labels">
                <ng-container *ngFor="let srv of services; let i = index">
                  <li class="align-middle">
                    <a
                      [ngClass]="{
                        'active-cat':
                          ('srv_' + cart.serviceType_ex | translate) ==
                          ('srv_' + srv | translate)
                      }"
                      (click)="setService(srv)"
                      class="btn btn-outline-secondary px-1"
                    >
                      <svg
                        *ngIf="i == 0"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 640 512"
                      >
                        <path
                          d="M112 0C85.5 0 64 21.5 64 48V96H16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 272c8.8 0 16 7.2 16 16s-7.2 16-16 16H64 48c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 240c8.8 0 16 7.2 16 16s-7.2 16-16 16H64 16c-8.8 0-16 7.2-16 16s7.2 16 16 16H64 208c8.8 0 16 7.2 16 16s-7.2 16-16 16H64V416c0 53 43 96 96 96s96-43 96-96H384c0 53 43 96 96 96s96-43 96-96h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V288 256 237.3c0-17-6.7-33.3-18.7-45.3L512 114.7c-12-12-28.3-18.7-45.3-18.7H416V48c0-26.5-21.5-48-48-48H112zM544 237.3V256H416V160h50.7L544 237.3zM160 368a48 48 0 1 1 0 96 48 48 0 1 1 0-96zm272 48a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z"
                        />
                      </svg>

                      <svg
                        *ngIf="i == 1"
                        xmlns="http://www.w3.org/2000/svg"
                        width="20.149"
                        viewBox="0 0 20.149 20.154"
                      >
                        <g
                          id="Icon_ionic-ios-timer"
                          data-name="Icon ionic-ios-timer"
                          transform="translate(-3.938 -3.938)"
                        >
                          <path
                            fill="#707070"
                            id="Path_2306"
                            data-name="Path 2306"
                            d="M14.015,24.092A10.077,10.077,0,0,1,7.021,6.759.809.809,0,0,1,8.145,7.923,8.457,8.457,0,1,0,14.821,5.6V8.749a.811.811,0,1,1-1.622,0v-4a.811.811,0,0,1,.811-.811,10.077,10.077,0,0,1,.005,20.154Z"
                            transform="translate(0)"
                            fill="#707070"
                          />
                          <path
                            fill="#707070"
                            id="Path_2307"
                            data-name="Path 2307"
                            d="M12.052,11.346l5.013,3.6A1.517,1.517,0,1,1,15.3,17.418a1.465,1.465,0,0,1-.353-.353l-3.6-5.013a.505.505,0,0,1,.705-.705Z"
                            transform="translate(-2.073 -2.073)"
                            fill="#707070"
                          />
                        </g>
                      </svg>

                      <svg
                        *ngIf="i == 2"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 448 512"
                      >
                        <path
                          fill="#707070"
                          d="M320 48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM125.7 175.5c9.9-9.9 23.4-15.5 37.5-15.5c1.9 0 3.8 .1 5.6 .3L137.6 254c-9.3 28 1.7 58.8 26.8 74.5l86.2 53.9-25.4 88.8c-4.9 17 5 34.7 22 39.6s34.7-5 39.6-22l28.7-100.4c5.9-20.6-2.6-42.6-20.7-53.9L238 299l30.9-82.4 5.1 12.3C289 264.7 323.9 288 362.7 288H384c17.7 0 32-14.3 32-32s-14.3-32-32-32H362.7c-12.9 0-24.6-7.8-29.5-19.7l-6.3-15c-14.6-35.1-44.1-61.9-80.5-73.1l-48.7-15c-11.1-3.4-22.7-5.2-34.4-5.2c-31 0-60.8 12.3-82.7 34.3L57.4 153.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0l23.1-23.1zM91.2 352H32c-17.7 0-32 14.3-32 32s14.3 32 32 32h69.6c19 0 36.2-11.2 43.9-28.5L157 361.6l-9.5-6c-17.5-10.9-30.5-26.8-37.9-44.9L91.2 352z"
                        />
                      </svg>

                      {{ "srv_" + srv | translate }}
                    </a>
                  </li>
                </ng-container>
              </ul>
            </div>
          </div>
        </section>
      </section>

      <section
        *ngIf="allowShipping && selectedService != 'pickup' && !forCatering"
        class="categories-labels-sec py-3 border-bottom-none"
      >
        <div class="container">
          <div
            style="border-bottom: 2px solid #f5f5f5"
            class="row justify-content-start align-items-start pt-3"
          >
            <div class="col-auto">
              <div class="side-menu">
                <span class="font-weight-bold px-2 color-black">{{
                  "Select Country" | translate
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="min-height: 100%" class="container px-0 bg-white">
          <div class="container">
            <span class="px-1" *ngIf="country" (click)="openCountry()">{{
              country | iknLocalized
            }}</span>
            <span class="px-1" *ngIf="country" (click)="openCountry()"
              ><i class="fa fa-pencil cursor-pointer color-primary"></i
            ></span>
            <span class="px-1" *ngIf="!country" (click)="openCountry()">{{
              "Choose your country" | translate
            }}</span>
          </div>
        </div>
      </section>

      <section
        *ngIf="selectMode == 'areas'"
        class="categories-labels-sec py-3 border-bottom-none"
      >
        <div *ngIf="!canSelectService" class="container">
          <div
            style="border-bottom: 2px solid #f5f5f5"
            class="row justify-content-start align-items-start pt-3"
          >
            <div class="col-auto">
              <div class="side-menu">
                <span class="font-weight-bold px-2 color-black">{{
                  "Select Area" | translate
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="min-height: 100%" class="container px-0 bg-white">
          <section style="border-bottom: 1px solid #f5f5f5" class="search-sec">
            <div class="container">
              <div class="row">
                <div class="col-12 px-0">
                  <div class="form-group m-0">
                    <div class="input-group input-group-abs">
                      <div class="input-group-prepend">
                        <i class="icon-search"></i>
                      </div>

                      <input
                        [(ngModel)]="searchText"
                        style="
                          border: none;
                          background-color: #fff;
                          border-radius: 0 !important;
                        "
                        type="text"
                        class="form-control rounded-0 search-location w-100 px-5"
                        placeholder="{{ 'Search for area' | translate }}"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <ng-container *ngFor="let governate of defaultCountryGovernates">
                <ng-container
                  *ngIf="governate.areas | filter : searchText as results"
                >
                  <div
                    style="cursor: pointer"
                    (click)="onGovernate(governate)"
                    *ngIf="results.length"
                    class="row justify-content-between align-items-center py-3"
                  >
                    <div style="font-weight: 700" class="col-auto">
                      <span class="color-black">{{
                        governate.name | iknLocalized
                      }}</span>
                    </div>
                    <div class="col-auto">
                      <span>
                        <svg
                          *ngIf="!(governate.flag || searchText)"
                          style="width: 15px !important"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            style="fill: gray; cursor: pointer"
                            d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z"
                          />
                        </svg>
                        <svg
                          *ngIf="governate.flag || searchText"
                          style="width: 15px !important"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            style="fill: gray; cursor: pointer"
                            d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z"
                          />
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div style="cursor: pointer">
                    <ng-container *ngIf="governate.flag || searchText">
                      <ng-container *ngFor="let area of results">
                        <div
                          (click)="saveArea(area)"
                          style="border-top: 1px solid #f5f5f5"
                          class="row justify-content-between align-items-center py-2"
                        >
                          <div style="font-weight: 500" class="col-auto">
                            <span class="color-black">{{
                              area.name | iknLocalized
                            }}</span>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </section>
        </div>
      </section>

      <section
        *ngIf="selectMode == 'branches'"
        class="categories-labels-sec py-3 border-bottom-none"
      >
        <div class="container">
          <div
            style="border-bottom: 2px solid #f5f5f5"
            class="row justify-content-start align-items-start pt-3"
          >
            <div class="col-auto">
              <div class="side-menu">
                <span class="font-weight-bold px-2 color-black">{{
                  "Location" | translate
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="min-height: 100%" class="container px-0 bg-white">
          <section style="border-bottom: 1px solid #f5f5f5" class="search-sec">
            <div class="container">
              <div class="row">
                <div class="col-12 px-0">
                  <div class="form-group m-0">
                    <div class="input-group input-group-abs">
                      <div class="input-group-prepend">
                        <i class="icon-search"></i>
                      </div>

                      <input
                        [(ngModel)]="searchText"
                        style="
                          border: none;
                          background-color: #fff;
                          border-radius: 0 !important;
                        "
                        type="text"
                        class="form-control rounded-0 search-location w-100 px-5"
                        placeholder="{{ 'Search for area' | translate }}"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container">
              <ng-container *ngFor="let branch of branches">
                <div
                  (click)="saveBranch(branch)"
                  class="row justify-content-between align-items-center py-3"
                >
                  <div style="font-weight: 700" class="col-auto">
                    <span class="color-black">{{
                      branch.name | iknLocalized
                    }}</span>
                  </div>
                  <!-- <div class="col-auto">
                                        <span>
                                            <a [routerLink]="['/branch-details', branch.id ]">
                                                <img src="../../assets/images/img5.png" width="20" height="20">
                                            </a>
                                            
                                        </span>
                                    </div> -->
                </div>
              </ng-container>
            </div>
          </section>
        </div>
      </section>

      <section
        *ngIf="selectMode == 'city'"
        class="categories-labels-sec py-3 border-bottom-none"
      >
        <div *ngIf="!canSelectService" class="container">
          <div
            style="border-bottom: 2px solid #f5f5f5"
            class="row justify-content-start align-items-start pt-3"
          >
            <div class="col-auto">
              <div class="side-menu">
                <span class="font-weight-bold px-2 color-black">{{
                  "Select City" | translate
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div style="min-height: 100%" class="container px-0 bg-white">
          <section style="border-bottom: 1px solid #f5f5f5" class="search-sec">
            <div class="container">
              <div class="row">
                <div class="col-12 px-0">
                  <div class="form-group m-0">
                    <div class="input-group input-group-abs">
                      <div class="input-group-prepend">
                        <i class="icon-search"></i>
                      </div>

                      <input
                        style="
                          border: none;
                          background-color: #fff;
                          border-radius: 0 !important;
                        "
                        type="text"
                        class="form-control rounded-0 search-location w-100 px-5"
                        placeholder="{{ 'Search for city' | translate }}"
                        [(ngModel)]="searchText"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              iknLoader
              [isActive]="isLoadingCities"
              class="loader-container"
            ></div>
          </section>
          <section>
            <div class="container">
              <ng-container
                *ngFor="let governate of governates | filter : searchText"
              >
                <div
                  (click)="onGovernate(governate)"
                  class="row justify-content-between align-items-center py-3"
                >
                  <div style="font-weight: 700" class="col-auto">
                    <span class="color-black">{{
                      governate.name | iknLocalized
                    }}</span>
                  </div>
                  <div class="col-auto"></div>
                </div>
              </ng-container>
            </div>
          </section>
        </div>
      </section>

      <!-- <section  class="categories-labels-sec py-3  border-bottom-none ">
                <div class="container">
                    <div style="border-bottom: 2px solid #F5F5F5;" class="row justify-content-start align-items-start pt-3">
                        <div class="col-auto">
                            <div class="side-menu">
                                <span class="font-weight-bold px-2  color-black">Location</span>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div style="min-height: 100%;"  class="container px-0 bg-white">
                       
                        <section style="border-bottom: 1px solid #F5F5F5;" class="search-sec">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12 px-0">
                                        <div class="form-group m-0">
                                            <div class="input-group input-group-abs">
                                                <div class="input-group-prepend">
                                                    <i class="icon-search"></i>
                                                </div>
                                                <input style="border: none; background-color: #fff; border-radius: 0 !important; " type="text" class="form-control rounded-0 search-location" placeholder="Search">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                        <section>
                            <div class="container">
                                <div data-toggle="collapse" data-target="#collapseExample"
                                    class="row justify-content-between align-items-center py-3">
                                    <div style="font-weight: 700;" class="col-auto">
                                        <span class="color-black">Mansoura</span>
                                    </div>
                                    <div class="col-auto">
                                        <span>
                                            <svg style="width: 15px !important" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path style="fill: gray; cursor: pointer;"
                                                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                                            </svg>
                                            <svg style="width: 15px !important" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path style="fill: gray; cursor: pointer;"
                                                    d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                                            </svg>
                                        </span>
                                    </div>
                        
                                </div>
                                <div class="collapse" id="collapseExample">
                                    <div style="border-top: 1px solid #F5F5F5;" class="row justify-content-between align-items-center py-2">
                                        <div style="font-weight: 500;" class="col-auto">
                                            <span class="color-black">Mansoura 1</span>
                                        </div>
                        
                        
                                    </div>
                                    <div style="border-top: 1px solid #F5F5F5;" class="row justify-content-between align-items-center py-2">
                                        <div style="font-weight: 500;" class="col-auto">
                                            <span class="color-black">Mansoura 2</span>
                                        </div>
                        
                        
                                    </div>
                                </div>
                                <div  data-toggle="collapse" data-target="#collapseExample2"
                                 class="row justify-content-between align-items-center py-3">
                                    <div style="font-weight: 700;" class="col-auto">
                                        <span class="color-black">Mansoura</span>
                                    </div>
                                    <div class="col-auto">
                                        <span>
                                            <svg style="width: 15px !important" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path style="fill: gray; cursor: pointer;"
                                                    d="M233.4 406.6c12.5 12.5 32.8 12.5 45.3 0l192-192c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L256 338.7 86.6 169.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l192 192z" />
                                            </svg>
                                            <svg style="width: 15px !important" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                <path style="fill: gray; cursor: pointer;"
                                                    d="M233.4 105.4c12.5-12.5 32.8-12.5 45.3 0l192 192c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L256 173.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l192-192z" />
                                            </svg>
                                        </span>
                                    </div>
                        
                                </div>
                                <div class="collapse" id="collapseExample2">
                                    <div style="border-top: 1px solid #F5F5F5;" class="row justify-content-between align-items-center py-2">
                                        <div style="font-weight: 500;" class="col-auto">
                                            <span class="color-black">Mansoura 3</span>
                                        </div>
                        
                        
                                    </div>
                                    <div style="border-top: 1px solid #F5F5F5;" class="row justify-content-between align-items-center py-2">
                                        <div style="font-weight: 500;" class="col-auto">
                                            <span class="color-black">Mansoura 4</span>
                                        </div>
                        
                        
                                    </div>
                                </div>
                            </div>
                        </section>
                    
                </div>
            </section> -->
    </div>
  </div>
</div>

<app-country-selector
  (countryChange)="onCountryChange($event)"
></app-country-selector>
<!-- <app-footer></app-footer> -->
