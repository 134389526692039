import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import {
  Area,
  governate,
  PaymentMethodsLookups,
  TruckInfo,
  AppEventType,
  DeliveryTime,
  Address,
  CountryListDto,
  DeliveryTimeWrapper,
  LocalizedField,
} from "../models";
import { ApiService } from "../api.service";
import { UtilsService } from "../utils.service";
import { CartService } from "../cart.service";
import { Cart } from "../cart";
// import * as _ from 'lodash';
import { AddressPartComponent } from "../address-part/addres-part.component";
import { NavigationEnd, Router } from "@angular/router";
import { slideInUpOnEnterAnimation } from "angular-animations";
import { IknDatePipe } from "../ikn-date.pipe";
import { TranslateService } from "@ngx-translate/core";
import {
  CountryISO,
  SearchCountryField,
  TooltipLabel,
} from "ngx-intl-tel-input";
import { NgxSmartModalService } from "ngx-smart-modal";

import { ToastrService } from "ngx-toastr";

import Swal from "sweetalert2";
import { environment } from "src/environments/environment";
import * as _ from "lodash";
import { Observable } from "rxjs";
import { FormBuilder, FormGroup, NgForm, Validators } from "@angular/forms";
import { SubstitutionComponent } from "../substitution/substitution.component";
import { AreaComponent } from "../area/area.component";
import { AgmMap } from "@agm/core";
import { filter } from "rxjs/operators";
import { GoogleTagManagerService } from "../google-tag-manager.service";

@Component({
  selector: "app-pre-checkout",
  templateUrl: "./pre-checkout.component.html",
  styleUrls: ["./pre-checkout.component.scss"],
  animations: [slideInUpOnEnterAnimation({ delay: 1000 })],
  providers: [IknDatePipe],
})
export class PreCheckoutComponent implements OnInit, AfterViewInit {
  truck: TruckInfo;
  continueAs: string = "guest";
  cart: Cart;
  selectedService: string;
  services = [];
  isLoading: boolean = false;
  isslotsLoading: boolean = false;
  isShippingMode: boolean = false;
  areas = [];
  governates = [];
  selectedArea: Area;
  governate: governate;
  searchText: string;
  _oldArea: number;
  results = [];
  paymentMethod: string = "";
  isShipping: boolean;
  paymentMethods: PaymentMethodsLookups[];
  SearchCountryField = SearchCountryField;
  TooltipLabel = TooltipLabel;
  CountryISO = CountryISO;
  preferredCountries: CountryISO[] = [CountryISO.Kuwait, CountryISO.Qatar];
  phoneNumber: any;
  cphoneNumber: any;
  selectedCountryCode: string;
  showArea = environment.show_area;
  areaRequired = environment.area_required;
  showAllAddressTypes = environment.showAllAddressTypes;

  countries: CountryListDto[] = [];

  addressForm: FormGroup;

  acceptTerms = true;

  submitted = false;
  contactPhoneLengthError = false;
  cb;
  w;
  paymentInProgress = false;
  expectedDeliveryTime: Date;
  operatingHours: any = {};
  allowShipping = environment.allow_shipping;
  defaultCountry = environment.default_country;
  allowTerms = environment.allow_terms;
  changeAddressUrl: [string] = ["/pre-checkout"];
  @ViewChild("address") cmpAddress: AddressPartComponent;

  isOrderLoading: boolean = false;
  orderId: number;
  deliveryTimes: DeliveryTime[];
  pickupAddress: Address = {
    countryId: -1,
    areaId: -1,
    governateId: -1,
    contactPhone: "",
    instructions: "instructions",
  };
  cardPayments = [];

  paymentForm: FormGroup;

  termsForm: FormGroup;
  @ViewChild("frmPayment") frmPayment: NgForm;
  @ViewChild("frmContact") frmContact: NgForm;
  @ViewChild("frmTerms") frmTerms: NgForm;

  order: any;
  minDeliveryTime: string;

  verificationCode: string;
  confirmedVerificationCode: string;
  isOpen: boolean;

  allowSubstitution: boolean = environment.ask_for_substitution;
  allowPromoCodes = environment.allow_promo_codes;
  allowCash = !environment.disable_cash;

  cateringSubmitOrder = false;
  @ViewChild(SubstitutionComponent) substitutionSelector: SubstitutionComponent;
  @ViewChild(AreaComponent) areaSelector: AreaComponent;
  @ViewChild("agMap") agMap: AgmMap;
  isCalculatingShipping = false;
  addressType: number = 1;
  previousUrl: string = "/";
  constructor(
    private api: ApiService,
    private srvCart: CartService,
    private router: Router,
    public utils: UtilsService,
    public IknDatePipe: IknDatePipe,
    private trans: TranslateService,
    private modal: NgxSmartModalService,
    private translate: TranslateService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    private gtagService: GoogleTagManagerService
  ) {
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.previousUrl = event.url;
      });
    this.cart = srvCart.getCart();
    this.addressForm = this.formBuilder.group({
      contactName: ["", Validators.required],
      contactPhone: ["", Validators.required],
      cphoneNumber: ["", Validators.required],

      blockNo: [""],
      avenue: [""],
      street: [""],
      buildingNo: [""],
      floorNo: [""],
      flatNo: [""],
      //addressLine1: [''],
      title: [""],
      instructions: [""],
      countryId: [""],
      governateId: [""],
      areaId: [""],
      membershipNumber: [""],
    });
    this.isLoading = true;
    if (this.api.isLoggedIn) {
      let route = [];
      if (this.cart.orderInfo.pickupAddress.selectedAddressId) {
      } else {
        route = ["select-address"];
        this.router.navigate(route, {
          replaceUrl: true,
          skipLocationChange: true,
        });
        return;
      }
    }
    if (this.cart.serviceType_ex == "catering") {
      this.continueAs = "login";
    }
    if (this.cart.serviceType_ex == "pickup") {
      //this.router.navigate(['/checkout'], { skipLocationChange: true, replaceUrl: true });
      return;
    }

    if (this.cart.serviceType_ex == "soonest_delivery") {
      this.isslotsLoading = true;
      this.api
        .getDeliveryTimes(
          new Date(),
          "delivery",
          this.api._orderFromCart(this.cart).cartItems
        )
        .subscribe((t) => {
          this.cart.orderInfo.pickupTime = t.asSoonAsFrom;
          if (t.asSoonAsFrom != t.asSoonAsTo)
            this.cart.orderInfo.pickupTimeTo = t.asSoonAsTo;

          var timeslot =
            this.IknDatePipe.transform(this.cart.orderInfo.pickupTime, "day") +
            " " +
            this.IknDatePipe.transform(this.cart.orderInfo.pickupTime, "date") +
            " " +
            this.IknDatePipe.transform(this.cart.orderInfo.pickupTime, "tf") +
            " " +
            this.IknDatePipe.transform(this.cart.orderInfo.pickupTimeTo, "tf");

          this.trans
            .get("DeliverTimeConfirmation", { timeslot: timeslot })
            .subscribe((msg) => {
              this.utils.confirm(msg, "");
            });
          this.isslotsLoading = false;
        });
    } else if (this.cart.serviceType_ex == "delivery") {
      this.isslotsLoading = true;
      this.api
        .getDeliveryTimes(
          new Date(),
          "delivery",
          this.api._orderFromCart(this.cart).cartItems
        )
        .subscribe((t) => {
          if (this.cart.orderInfo.pickupTime <= t.asSoonAsFrom) {
            this.cart.orderInfo.pickupTime = t.asSoonAsFrom;

            if (t.asSoonAsFrom != t.asSoonAsTo)
              this.cart.orderInfo.pickupTimeTo = t.asSoonAsTo;
          }
          var timeslot =
            this.IknDatePipe.transform(this.cart.orderInfo.pickupTime, "day") +
            " " +
            this.IknDatePipe.transform(this.cart.orderInfo.pickupTime, "date") +
            " " +
            this.IknDatePipe.transform(this.cart.orderInfo.pickupTime, "tf") +
            " " +
            this.IknDatePipe.transform(this.cart.orderInfo.pickupTimeTo, "tf");

          this.trans
            .get("DeliverTimeConfirmation", { timeslot: timeslot })
            .subscribe((msg) => {
              this.utils.confirm(msg, "");
            });
          this.isslotsLoading = false;
        });
    }
    let that = this;
    api.viewTruck().subscribe((t) => {
      this.isLoading = false;
      this.cart = srvCart.getCart();

      if (
        t.truck.allowShippingOutsideCountry &&
        this.cart.orderInfo.pickupAddress.countryId != t.truck.defaultCountry.id
      ) {
        this.isShippingMode = true;
      }
      if (this.cart.$truck)
        this.paymentMethods = this.cart.$truck.paymentMethodsLookups;
      if (this.cart.serviceType_ex == "catering") {
        this.paymentMethods = _.filter(
          this.paymentMethods,
          (d) => d.paymentMethod != "cashOnDelivery"
        );
      }

      let defaultCountry =
        (this.cart.$truck &&
          this.cart.$truck.defaultCountry &&
          this.cart.$truck.defaultCountry.id) ||
        environment.default_country;
      this.isShipping =
        this.pickupAddress.countryId &&
        this.pickupAddress.countryId != -1 &&
        this.pickupAddress.countryId != defaultCountry;
      if (!this.isShipping) {
        api
          .getDeliveryTimes(
            new Date(),
            this.cart.orderInfo.serviceType,
            this.api._orderFromCart(this.cart).cartItems
          )
          .subscribe((times) => {
            let laterOptions = (
              times.laterOptions || ([] as DeliveryTimeWrapper[])
            ).filter(
              (d) =>
                d.deliveryTime.type == "Custom" ||
                d.deliveryTime.type == "AsSoonAs"
            );
            this.deliveryTimes = laterOptions.map((d) => d.deliveryTime);
          });
      }
      if (!this.isShipping) this.srvCart.setShippingCharge(0);
      else {
        if (this.paymentMethod == "cashOnDelivery")
          this.paymentMethod =
            this.cardPayments[0] && this.cardPayments[0].value;
        this.isCalculatingShipping = true;
        this.api.calculateShipping(this.cart).subscribe((shipping) => {
          this.srvCart.setShippingCharge(shipping.shippingCharge);
          this.isCalculatingShipping = false;
        });
      }
      this.isLoading = false;
      //this.srvCart.setTruck('delivery', t.truck);
      this.truck = t.truck;
      this.allowShipping = t.truck.allowShippingOutsideCountry;
      this.allowSubstitution = t.truck.enableSubstitutionOptions;
      this.pickupAddress.areaId = this.cart.orderInfo.pickupAddress.areaId;
      var area = _.find(
        t.truck.areas,
        (d) => d.id == this.pickupAddress.areaId
      );
      if (area?.areaPaymentMethod == "Cash") {
        this.paymentMethods = t.truck.paymentMethodsLookups.filter(
          (d) => d.paymentMethod == "cashOnDelivery"
        );
      } else if (area?.areaPaymentMethod == "Online") {
        this.paymentMethods = t.truck.paymentMethodsLookups.filter(
          (d) => d.paymentMethod != "cashOnDelivery"
        );
      } else this.paymentMethods = t.truck.paymentMethodsLookups;
      if (t.truck.minDeliveryTime) {
        let parts = _.split(t.truck.minDeliveryTime, ":");
        that.minDeliveryTime = (
          Number(parts[0]) * 60 +
          Number(parts[1])
        ).toString();
        //that.minDeliveryTime = parts[1];
      }
      let cashonly = false;
      if (
        environment?.allow_Cash_Only != null &&
        environment?.allow_Cash_Only == true
      )
        cashonly = true;

      if (cashonly) {
        var name: LocalizedField = {
          ar: "نقداً (كاش)",
          en: "Cash On Delivery",
        };
        var paymentcash: PaymentMethodsLookups = {
          id: 1,
          paymentMethod: "cashOnDelivery",
          name: name,
        };
        this.paymentMethods = [];
        this.paymentMethods.push(paymentcash);
        this.paymentMethods = _.filter(
          this.paymentMethods,
          (d) => d.paymentMethod == "cashOnDelivery"
        );
      }

      that.isOpen =
        this.isShipping ||
        utils.isNowBetween(t.truck.operatingFrom, t.truck.operatingTo);
      that.operatingHours = {
        from: that.utils.parseTimeSpan(that.truck.operatingFrom),
        to: that.utils.parseTimeSpan(that.truck.operatingTo),
      };
      if (this.paymentMethods?.length > 0) {
        this.storePaymentMethod(this.paymentMethods[0].paymentMethod);
      }
    });

    if (environment.card_payment) {
      _.each(_.split(environment.card_payment, ","), (pm) => {
        this.cardPayments.push({
          name: "card_" + pm,
          value: pm,
        });
      });
    } else {
      this.cardPayments.push({
        name: "Online Payment",
        value: "myfatoorah",
      });
    }
    if (this.api.isLoggedIn) {
      let authUser = this.api.getAuthUser();
      this.cart.orderInfo.pickupAddress.contactEmail =
        authUser && authUser.profile.email;
      this.pickupAddress.contactEmail =
        this.cart.orderInfo.pickupAddress.contactEmail;
    }

    if (!this.cart.orderInfo.pickupAddress.areaId) {
      this.router.navigate(["/service"], {
        replaceUrl: true,
        skipLocationChange: true,
      });
    }
  }
  get f() {
    return this.addressForm.controls;
  }
  storeAddress() {}
  changeAddressType(type) {
    if (this.addressType != type) {
      this.addressType = type;
    }
  }
  ngOnInit() {
    this.api.viewTruck().subscribe((t) => {
      if (this.cart.serviceType_ex == "catering")
        this.selectedService = "soonest_delivery";

      if (_.includes(t.truck.services, "delivery")) {
        this.services.push("soonest_delivery");
        this.services.push("delivery");
        //we should add the as soon as as possible special method
      }
      if (_.includes(t.truck.services, "pickup")) {
        this.services.push("pickup");
      }

      if (!this.selectedService) this.selectedService = this.services[0];
    });
    this.pickupAddress = this.cart.orderInfo.pickupAddress || {};
    this.selectedCountryCode =
      this.pickupAddress.countryCode ||
      (this.cart.$truck &&
        this.cart.$truck.defaultCountry &&
        this.cart.$truck.defaultCountry.code2) ||
      "kw";

    this.phoneNumber = this.pickupAddress.contactPhone;

    const cartitemsforGtag = this.cart.cartItems.map((x) => {
      return {
        id: x.$item.id,
        name: x.$item.title.en,
        quantity: x.qty,
        price: x.unitPrice,
        extras: x.extras,
        category: "Products",
        brand: x.$item?.truckName?.en
          ? x.$item?.truckName?.en
          : x.$item?.truckName?.ar
          ? x.$item?.truckName?.ar
          : "Broker",
      };
    });
    console.log(cartitemsforGtag);
    this.gtagService.checkoutEventEmitter(cartitemsforGtag);
  }

  storePaymentMethod(paymentMethod) {
    this.paymentMethod = paymentMethod;
    this.srvCart.setPaymentMethod(this.paymentMethod);
  }
  ngAfterViewInit() {}
  goBack() {
    this.utils.goBack();
  }
  setService(service) {
    let oldService = this.selectedService;
    if (service == "soonest_delivery") {
      this.selectedService = service;
      this.srvCart.setServiceType("delivery", "soonest_delivery");
    } else {
      if (service !== "delivery") {
        this.srvCart.setServiceType(service);
      }
    }
    if (service === "delivery") {
      this.router.navigate(["time-date"]);
      return;
    }
    if (service === "pickup") {
      this.router.navigate(["cart"]);
      return;
    }

    this.router.navigate(["service"]);
  }
  save() {
    //TODO:
    this.router.navigate(["/checkout"]);
  }
  onGovernate(governate: governate) {
    this.governate = governate;
    governate.flag = !governate.flag;
  }
  isverificationCodeValid = true;
  verifyThePhoneCode() {
    if (this.recordingTimer == "00:00" || !this.confirmedVerificationCode) {
      this.isverificationCodeValid = false;
    } else {
      if (this.confirmedVerificationCode == this.verificationCode) {
        let serviceType = this.cart.orderInfo.serviceType;
        this.modal.getModal("mphoneValidator").close();
        const cartitemsforGtag = this.cart.cartItems.map((x) => {
          return {
            id: x.$item.id,
            name: x.$item.title.en,
            quantity: x.qty,
            price: x.unitPrice,
            category: "Products",
            brand: x.$item?.truckName?.en
              ? x.$item?.truckName?.en
              : x.$item?.truckName?.ar
              ? x.$item?.truckName?.ar
              : "Broker",
          };
        });

        this.api
          .isBlockedNumber(
            Number(
              this.cart.orderInfo.pickupAddress.contactPhone.split(" ").join("")
            )
          )
          .subscribe({
            next: (response) => {
              if (response == true) {
                let apiCall = this.api.submitOrder(this.cart);
                apiCall.subscribe(
                  (res) => {
                    //this.isOrderLoading = false;

                    this.modal.getModal("mphoneValidator").close();

                    if (
                      serviceType == "catering" ||
                      this.paymentMethod == "cashOnDelivery"
                    )
                      this.srvCart.emptyCart();
                    this.utils.fbTrack("Purchase", {
                      value: this.cart.$total,
                      currency: environment.currency_code,
                    });

                    this.utils.snapchatTrack("PURCHASE", {
                      price: this.cart.$total,
                      currency: environment.currency_code,
                    });

                    this.api.trackEvent(AppEventType.PlaceOrder, res.orderId);
                    let p = new Observable((o) => {
                      o.next();
                    });
                    if (
                      serviceType == "catering" &&
                      this.api.isLoggedIn &&
                      (!this.orderId || this.paymentMethod == "cashOnDelivery")
                    ) {
                      this.router.navigate([
                        "/my-orders",
                        {
                          id: res.orderId,
                          ot: res.type,
                          orderNo: res.orderNumber,
                        },
                      ]);
                      return;
                    }
                    if (
                      this.paymentMethod != "cashOnDelivery" &&
                      res.paymentUrl
                    ) {
                      p = this.payOnline(res, this.w);
                    }
                    p.subscribe(
                      (o) => {
                        //this.srvCart.emptyCart();
                        //this.success(this.translate.instant('Order submitted'), this.translate.instant('Thank you'));
                        this.isLoading = false;
                        if (!this.orderId) {
                          this.gtagService.purchaseEventEmitter(
                            cartitemsforGtag,
                            res.orderId,
                            this.cart.$total,
                            environment.currency_code,
                            this.cart.orderInfo.paymentMethod
                          );
                          this.router.navigate(
                            ["/order/" + res.orderId, { ot: res.type }],
                            { skipLocationChange: true, replaceUrl: true }
                          );
                        }
                      },
                      (err) => {
                        this.closePaymentWindow();
                        this.isLoading = false;

                        this.phoneNumber = this.pickupAddress.contactPhone;
                        //  this.utils.warn(this.translate.instant("WE_ARE_BUSY"), 'sorry');
                      }
                    );
                  },
                  (res) => {
                    this.isOrderLoading = false;
                    this.closePaymentWindow();

                    let err = res.error;
                    this.isLoading = false;
                    if (err && err.code == "5500") {
                      let msg = _.join(
                        _.map(err.errorDetail.errors, (e) =>
                          this.translate.instant("c_err_" + e.code, { err: e })
                        ),
                        "<br/>"
                      );
                      this.router.navigate(["/cart"]);
                      this.utils.warn(msg, "CART_CONFLICT").then(function () {
                        location.reload();
                      });

                      return;
                    } else {
                      let msg = this.translate.instant("err_" + err.code, {
                        type: this.translate.instant(
                          "srv_" + this.cart.orderInfo.serviceType
                        ),
                      });
                      if (msg && !_.includes(msg, "err")) {
                        this.utils.warn(msg, "CART_CONFLICT").then(function () {
                          this.router.navigate(["/cart"]);
                        });
                        return;
                      }
                    }
                    this.phoneNumber = this.pickupAddress.contactPhone;
                    //  this.utils.warn(this.translate.instant("WE_ARE_BUSY"), 'sorry');
                  }
                );
              } else {
                this.utils
                  .warn(
                    "Your Number has an issue or has been blocked",
                    "order can not be procced"
                  )
                  .then(function () {
                    location.reload();
                  });
              }
            },
            error: (error) => {
              this.toaster.error(this.translate.instant("error occured !"));
            },
          });
      } else {
        this.isverificationCodeValid = false;
      }
    }
  }
  onAreaChanged(area: Area) {
    this.srvCart.setArea(area);
    if (this.cart.serviceType_ex == "soonest_delivery") {
      this.api
        .getDeliveryTimes(
          new Date(),
          "delivery",
          this.api._orderFromCart(this.cart).cartItems
        )
        .subscribe((t) => {
          this.cart.orderInfo.pickupTime = t.asSoonAsFrom;
          if (t.asSoonAsFrom != t.asSoonAsTo)
            this.cart.orderInfo.pickupTimeTo = t.asSoonAsTo;
        });
    }
  }

  recordingTimer: any = "00:00";
  timer: boolean = true;
  seconds: number = 59;
  minutes: number = 2;
  timeInterval: any;
  recording() {
    ////// please type recording meeting functionality here
    if (this.timer) {
      this.seconds -= 1;

      if (this.seconds == 0) {
        this.seconds = 59;
        this.minutes -= 1;
      }
      if (this.minutes == -1 && this.seconds == 59) {
        this.minutes = 0;
        this.seconds = 0;
        this.timer = false;
        clearInterval(this.timeInterval);
      }

      /// update recording time
      this.recordingTimer =
        (this.minutes
          ? this.minutes > 9
            ? this.minutes
            : "0" + this.minutes
          : "00") +
        ":" +
        (this.seconds > 9 ? this.seconds : "0" + this.seconds);
    }
  }

  repeat() {
    clearInterval(this.timeInterval);
    this.timeInterval = setInterval(() => this.recording(), 1000);
  }

  // error(msg, title = "alert") {
  //   let code;
  //   if (msg) {
  //     if (msg.error) {
  //       if (msg.error.data) msg = msg.error.data;
  //       else msg = msg.error;
  //       code = msg.errorCode;
  //     }
  //     if (msg.errorDetail) {
  //       msg = msg.errorDetail;
  //       code = msg.code;
  //     }
  //     if (msg.errorMessage) {
  //       msg = msg.errorMessage;
  //       code = msg.code;
  //     }
  //     if (msg.message) {
  //       msg = msg.message;
  //       code = msg.code;
  //     }
  //   }
  //   if (code) {
  //     code = "err_" + code;
  //     let tmsg = this.translate.instant(code);
  //     if (tmsg !== code) {
  //       msg = code;
  //     }
  //   }
  //   return this.notify("warning", title, msg);
  // }
  // warn(msg, title = "warning") {
  //   return this.notify("warning", title, msg);
  // }
  // success(msg, title = "warning") {
  //   return this.notify("success", title, msg);
  // }
  // notify(type, title, msg) {
  //   if (!title) title = type;
  //   title = this.translate.instant(title);
  //   if (!msg) msg = type;
  //   msg = this.translate.instant(msg);

  //   const confirmButtonText = this.translate.instant("OK");
  //   return Swal.fire(title, msg, type);
  // }

  payOnline(data, x) {
    return new Observable((observable) => {
      this.paymentInProgress = true;

      this.cb = function (event) {
        if (event.data === "payment_confirmed") observable.next();
        else {
          observable.error("Your payment is rejected, please try again");
        }
        window.removeEventListener("message", this.cb);
        this.cb = undefined;
        this.closePaymentWindow();
      };
      window.addEventListener("message", this.cb, false);

      if (data.paymentUrl) x.location = data.paymentUrl;
    });
  }
  validatePhoneNumber() {
    if (this.phoneNumber && this.phoneNumber.countryCode == "KW") {
      if (
        !this.phoneNumber?.number ||
        this.phoneNumber?.number?.replace(/\s/g, "").length != 8 ||
        this.phoneNumber?.number?.replace(/\s/g, "").startsWith("0") ||
        this.phoneNumber?.number?.replace(/\s/g, "").startsWith("1") ||
        this.phoneNumber?.number?.replace(/\s/g, "").startsWith("3") ||
        this.phoneNumber?.number?.replace(/\s/g, "").startsWith("7") ||
        this.phoneNumber?.number?.replace(/\s/g, "").startsWith("8") ||
        this.cphoneNumber?.number != this.phoneNumber?.number
      ) {
        return true;
      }
      this.addressForm.controls["contactPhone"].setErrors(null);
      return false;
    }
  }
  closePaymentWindow() {
    try {
      this.w.close();
    } catch (err) {}
  }

  sendVerificationCode() {
    this.timer = true;
    this.seconds = 59;
    this.minutes = 2;

    this.repeat();
    this.isverificationCodeValid = true;
    let apiCall = this.api.sendVerificationCoe({
      customerPhone: this.phoneNumber?.internationalNumber?.trim(),
      truckId: this.truck.id,
    });
    apiCall.subscribe(
      (res) => {
        this.confirmedVerificationCode = res.verifcationCode;
      },
      (res) => {
        this.confirmedVerificationCode = res.verifcationCode;
      }
    );
  }

  changeCode() {
    this.isverificationCodeValid = true;
  }

  submitOrder(confirmed: boolean = false) {
    this.submitted = true;

    // console.log("🚀 ~ file: pre-checkout.component.ts:693 ~ PreCheckoutComponent ~ submitOrder ~ this.phoneNumber:", this.phoneNumber)

    // if(!this.pickupAddress.location.lng && !this.pickupAddress.location.lat)
    // {
    //   this.invalidLocation = true;
    //   return;
    // }
    if (this.isOrderLoading)
      //already loading order
      return;
    if (this.addressType == 2) {
      if (this.validatePhoneNumber()) {
        return;
      }
      this.pickupAddress.contactPhone = this.phoneNumber.internationalNumber;
      this.pickupAddress.countryCode = this.phoneNumber.countryCode;
      this.cart.orderInfo.pickupAddress.contactPhone =
        this.phoneNumber.internationalNumber || this.phoneNumber;
      this.pickupAddress.addressLine1 = null;
      //
    } else {
      this.cart.orderInfo.pickupAddress.contactPhone =
        this.pickupAddress.contactPhone;
      this.cart.orderInfo.pickupAddress.countryCode =
        this.pickupAddress.countryCode;
    }
    // console.log("🚀 ~ file: pre-checkout.component.ts:693 ~ PreCheckoutComponent ~ submitOrder ~ this.phoneNumber:", this.cart)
    // return

    this.srvCart.saveCart();

    this.isOrderLoading = true;
    if (!this.cateringSubmitOrder) {
      if (this.paymentMethods.length) {
        // this.frmPayment.ngSubmit.emit();
        // if (!this.paymentForm.valid) {
        //     this.isOrderLoading = false;
        //     return;
        // }
      }
      if (this.allowTerms) {
        this.frmTerms.ngSubmit.emit();
        if (!this.termsForm.valid) {
          this.isOrderLoading = false;
          return;
        }
      }
      // this.frmContact.ngSubmit.emit();
      // if (!this.contactForm.valid) {
      //     this.isOrderLoading = false;
      //     return;
      // }
    }

    let defaultCountry =
      (this.cart.$truck &&
        this.cart.$truck.defaultCountry &&
        this.cart.$truck.defaultCountry.id) ||
      environment.default_country;
    let isShipping =
      this.pickupAddress.countryId &&
      this.pickupAddress.countryId != -1 &&
      this.pickupAddress.countryId != defaultCountry;
    if (!this.isOpen && !isShipping && !this.cart.orderInfo.pickupTime) {
      this.utils.warn(
        this.translate.instant("operating_hours_warning", this.operatingHours),
        "closed_warning"
      );
      this.isOrderLoading = false;
      return;
    }

    if (!this.orderId && !this.srvCart.validateCart()) {
      this.isOrderLoading = false;
      return;
    }

    if (this.allowTerms && !this.acceptTerms) {
      this.utils.warn(
        this.translate.instant("MUST_ACCEPT_TERMS", this.operatingHours),
        "Sorry"
      );
      this.isOrderLoading = false;
      return;
    }
    this.srvCart.setPaymentMethod(this.paymentMethod);
    this.submitted = true;
    if (this.contactPhoneLengthError) {
      this.isOrderLoading = false;
      return false;
    }
    this.contactPhoneLengthError = false;

    if (!isShipping && this.areaRequired && !this.pickupAddress.areaId) {
      this.isOrderLoading = false;
      this.areaSelector?.selectArea(() => {
        this.submitOrder(); //call submit order again if area is selected
      });
      return;
    }

    // Required Substitution
    if (this.allowSubstitution && !this.cart.orderInfo.substitution) {
      this.isOrderLoading = false;
      this.substitutionSelector.selectSubstitution(() => {
        this.submitOrder();
      });
      return;
    }

    if (this.isLoading) {
      this.isOrderLoading = false;
      return; //already loading something
    }
    if (!confirmed && this.utils.askForMembershipModal.askForMembership) {
      this.isOrderLoading = false;
      this.utils.askForMembershipModal.showModal(() => {
        //Do Nothing?
        this.submitOrder(true);
      });
      return;
    }

    this.cart.orderInfo.pickupAddress.areaId = this.pickupAddress.areaId;
    if (this.paymentMethod != "cashOnDelivery") {
      var w = 800;
      var h = 600;
      var left = screen.width / 2 - w / 2;
      var top = screen.height / 2 - h / 2;
      this.w = window.open("", "_self");

      window.focus();
    }
    this.isLoading = true;
    this.utils.fbTrack("InitiateCheckout");
    let serviceType = this.cart.orderInfo.serviceType;

    let otp = true;
    if (
      environment?.enable_OTP_Phone != null &&
      environment?.enable_OTP_Phone == false
    )
      otp = false;

    if (
      this.paymentMethod == "cashOnDelivery" &&
      this.truck.enableOTPPhoneVerification &&
      otp
    ) {
      this.sendVerificationCode();
      this.modal.getModal("mphoneValidator").open();

      return;
    }

    this.api
      .isBlockedNumber(
        Number(
          this.cart.orderInfo.pickupAddress.contactPhone.split(" ").join("")
        )
      )
      .subscribe({
        next: (response) => {
          if (response == true) {
            let apiCall = this.api.submitOrder(this.cart);
            apiCall.subscribe(
              (res) => {
                var cartitemsIds = this.cart.cartItems.map((x) => x.$item.id);
                var cartitemsforTiktok = this.cart.cartItems.map((x) => {
                  return {
                    content_id: x.$item.id,
                    content_type: "product",
                    content_name: x.$item.title.ar,
                    quantity: x.qty,
                    price: x.unitPrice,
                  };
                });
                const cartitemsforGtag = this.cart.cartItems.map((x) => {
                  return {
                    id: x.$item.id,
                    name: x.$item.title.en,
                    quantity: x.qty,
                    price: x.unitPrice,
                    category: "Products",
                    brand: x.$item?.truckName?.en
                      ? x.$item?.truckName?.en
                      : x.$item?.truckName?.ar
                      ? x.$item?.truckName?.ar
                      : "Broker",
                  };
                });

                if (
                  serviceType == "catering" ||
                  this.paymentMethod == "cashOnDelivery"
                )
                  this.srvCart.emptyCart();
                this.utils.fbTrack("Purchase", {
                  value: this.cart.$total,
                  currency: environment.currency_code,
                  content_type: "product",
                  content_ids: cartitemsIds,
                });

                this.utils.ttqTrack("Purchase", {
                  contents: cartitemsforTiktok,
                  value: this.cart.$total,
                  currency: environment.currency_code,
                });

                this.utils.snapchatTrack("PURCHASE", {
                  price: this.cart.$total,
                  currency: environment.currency_code,
                });

                this.api.trackEvent(AppEventType.PlaceOrder, res.orderId);
                let p = new Observable((o) => {
                  o.next();
                });
                if (
                  serviceType == "catering" &&
                  this.api.isLoggedIn &&
                  (!this.orderId || this.paymentMethod == "cashOnDelivery")
                ) {
                  this.gtagService.purchaseEventEmitter(
                    cartitemsforGtag,
                    res.orderId,
                    this.cart.$total,
                    environment.currency_code,
                    this.cart.orderInfo.paymentMethod
                  );
                  this.router.navigate([
                    "/my-orders",
                    { id: res.orderId, ot: res.type, orderNo: res.orderNumber },
                  ]);
                  return;
                }
                if (this.paymentMethod != "cashOnDelivery" && res.paymentUrl) {
                  p = this.payOnline(res, this.w);
                }
                p.subscribe(
                  (o) => {
                    //this.srvCart.emptyCart();
                    //this.success(this.translate.instant('Order submitted'), this.translate.instant('Thank you'));
                    this.isLoading = false;
                    if (!this.orderId)
                      this.gtagService.purchaseEventEmitter(
                        cartitemsforGtag,
                        res.orderId,
                        this.cart.$total,
                        environment.currency_code,
                        this.cart.orderInfo.paymentMethod
                      );
                    this.router.navigate(
                      ["/order/" + res.orderId, { ot: res.type }],
                      {
                        skipLocationChange: true,
                        replaceUrl: true,
                      }
                    );
                  },
                  (err) => {
                    this.closePaymentWindow();
                    this.isLoading = false;

                    this.phoneNumber = this.pickupAddress.contactPhone;
                    //  this.utils.warn(this.translate.instant("WE_ARE_BUSY"), 'sorry');
                  }
                );
              },
              (error) => {
                this.isOrderLoading = false;
                this.closePaymentWindow();

                let err = error.error;
                this.isLoading = false;
                if (err && err.code == "5500") {
                  let msg = _.join(
                    _.map(err.errorDetail.errors, (e) =>
                      this.translate.instant("c_err_" + e.code, { err: e })
                    ),
                    "<br/>"
                  );
                  this.router.navigate(["/cart"]);
                  this.utils.warn(msg, "CART_CONFLICT").then(function () {
                    location.reload();
                  });

                  return;
                } else {
                  let msg = this.translate.instant("err_" + err.code, {
                    type: this.translate.instant(
                      "srv_" + this.cart.orderInfo.serviceType
                    ),
                  });
                  if (msg && !_.includes(msg, "err")) {
                    this.utils.warn(msg, "CART_CONFLICT").then(function () {
                      this.router.navigate(["/cart"]);
                    });
                    return;
                  }
                }
                this.phoneNumber = this.pickupAddress.contactPhone;
                this.utils.error(err);
                //  this.utils.warn(this.translate.instant("WE_ARE_BUSY"), 'sorry');
              }
            );
          } else {
            this.utils
              .warn(
                "Your Number has an issue or has been blocked",
                "order can not be procced"
              )
              .then(function () {
                location.reload();
              });
          }
        },
        error: (error) => {
          this.toaster.error(this.translate.instant("error occured !"));
        },
      });
  }
}
